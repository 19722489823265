import React from 'react';
import SVGInline from 'react-svg-inline';
import styled from 'styled-components';

const Navigation = styled.div`
    display: flex;
    position: absolute;
    vertical-align: center;
    top: 0.5rem;
    right: 0;
`;

const CalendarButton = styled.div`
    border: 1px solid #4a90e2;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 7px;
    margin-right: 0.5rem;
    position: relative;
    width: 1rem;
    height: 1rem;

    &:last-child {
        margin-right: 0;
    }
    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
        width: 1rem;
        height: 1rem;
        fill: #4a90e2;
        stroke: #4a90e2;
    }
`;

const DatePickerNavigationHeader = ({
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick,
    className,
    onMonthNavigation,
    onMonthNavigationTracking,
}) => {
    return (
        <Navigation className={className}>
            <CalendarButton
                onClick={() => {
                    onPreviousClick();
                    if (onMonthNavigation) {
                        onMonthNavigation(previousMonth);
                    }
                    if (onMonthNavigationTracking) {
                        onMonthNavigationTracking('Previous month');
                    }
                }}
            >
                <SVGInline
                    svg={
                        '<svg viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path d="M7.727 2.273l-5.74 5.74m5.74 5.714L2.17 8.17" stroke="#4a90e2" stroke-width="2" fill="none" stroke-linecap="square"/></svg>'
                    }
                />
            </CalendarButton>
            <CalendarButton
                onClick={() => {
                    onNextClick();
                    if (onMonthNavigation) {
                        onMonthNavigation(nextMonth);
                    }
                    if (onMonthNavigationTracking) {
                        onMonthNavigationTracking('Next month');
                    }
                }}
            >
                <SVGInline
                    svg={
                        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16"><path d="M2.273 2.273l5.74 5.74m-5.74 5.714L7.83 8.17" fill="none" fill-rule="evenodd" stroke="#4a90e2" stroke-linecap="square" stroke-width="2"/></svg>'
                    }
                />
            </CalendarButton>
        </Navigation>
    );
};

export default DatePickerNavigationHeader;
