import React from 'react';
import { Sticky } from 'react-sticky';

const StickyComponent = (props) => {
    const { topOffset, className, children } = props;
    return (
        <Sticky topOffset={-topOffset}>
            {({ style, distanceFromBottom }) => {
                style = {
                    ...style,
                    ...{
                        top: distanceFromBottom > topOffset ? topOffset : distanceFromBottom,
                    },
                };
                return (
                    <div style={style} className={className}>
                        {children}
                    </div>
                );
            }}
        </Sticky>
    );
};

export default StickyComponent;
