import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
    width: 100%;
    height: 2.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 2rem;
    padding-left: 1rem;
    color: ${(props) => (props.hasError ? '#eb4c52' : '#747474')};
    border-radius: 8px;
    border: 1px solid ${(props) => (props.hasError ? '#eb4c52' : '#cecece')};
    background: white url(${(props) => props.icon}) no-repeat calc(100% - 1rem) center;

    :hover {
        outline: 1px solid ${(props) => (props.hasError ? '#C53030' : '#747474')};
        border-color: ${(props) => (props.hasError ? '#C53030' : '#747474')};
    }
`;

const Select = ({ name, options, onChange, required, hasError, value, className }) => {
    return (
        <StyledSelect
            name={name}
            required={required}
            onChange={onChange}
            value={value}
            className={className}
            hasError={hasError}
            icon={`https://static1.pawshakecdn.com/next/icons/icon-drop${hasError ? '-red' : ''}.svg`}
        >
            {options.map((option, index) => {
                return (
                    <option key={index} value={option.value} hidden={option.isPlaceholder}>
                        {option.label}
                    </option>
                );
            })}
        </StyledSelect>
    );
};

export default Select;
