import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
    border-radius: 50%;
`;

const Avatar = ({ src, alt, className }) => {
    const source =
        typeof src === 'undefined' || src === null
            ? 'https://static1.pawshakecdn.com/icons/icon-placeholder-user.svg'
            : src;

    return <Image src={source} alt={alt} title={alt} className={className} width={100} height={100} />;
};

export default Avatar;
